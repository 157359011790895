import { CONFIG } from "./config.js";

const sentryInit = () => {
  return {
    dsn: CONFIG.sentryDSN(),
    sampleRate: CONFIG.sampleRate(),
    integrations: [],
    enabled: CONFIG.enabled(),
    environment: CONFIG.environment(),
  };
};
export default sentryInit;
