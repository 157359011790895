import { startTransition } from "react";
import { hydrateRoot } from "react-dom/client";

import { RemixBrowser } from "@remix-run/react";

import * as Sentry from "@sentry/remix";

import sentryInit from "./sentry/sentryInit";

Sentry.init(sentryInit());

startTransition(() => {
  hydrateRoot(document, <RemixBrowser />);
});
